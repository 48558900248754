<template>
  <div id="app">
    <div v-if="errorMessage" class="error">
      {{ errorMessage }}
    </div>
    <div v-else class="app-container">
      <div v-if="isLoadingDossier" class="skeleton-container">
        <SkeletonLoader v-if="isLoadingDossier"></SkeletonLoader>
      </div>
      <div v-else>
        <FormulaireInfo
          v-if="(etatFormulaire === '⏳ En attente de réponse' || etatFormulaire === 'À envoyer' ) && (dossierState === 'Réalisation des démarches' || dossierState === 'Prévalidation')"
          :emailPouvoir="emailPouvoir"
          :tallyLink="tallyLink"
          :etatFormulaire="etatFormulaire"
          :isSendingMail="isSendingMail"
          :mailSent="mailSent"
          @renvoyer-mail="renvoyerMail"
        />
        <ComplementInfo
          v-if="dossierState === 'En attente de complément d\'informations'"
          :isLoadingDocuments="isLoadingDocuments"
          :commentsRd="commentsRd"
          :uploadedFiles="uploadedFiles"
          :isDocumentManquant="isDocumentManquant"
          :handleFileUpload="handleFileUpload"
          :isPdf="isPdf"
          :getObjectURL="getObjectURL"
          :removeFile="removeFile"
          :modifierDocuments="modifierDocuments"
        />
        <InfoForm 
          v-if="dossierState === 'En attente de lancement'"
          :idDossier="idDossier" 
          :dossierState="dossierState || ''" 
          :scanEnCours="scanEnCours" 
          :email="email" 
          :phone="telephone" 
          :comments="comments" 
          :siteMappings="siteMappings" 
          :siteMappingsReady="siteMappingsReady"
          @update-comptes="updateComptes" 
          @clear-comptes="clearComptes" 
          @update-email="updateEmail" 
          @update-phone="updatePhone" 
          @update-comments="updateComments" 
          @scan-en-cours="setScanEnCours" 
        />
        <InfoFormReadOnly
          v-if="dossierState === 'Réalisation des démarches' || dossierState === 'Stoppé' || dossierState === 'Prévalidation' || dossierState === 'Termin��'"
          :email="email"
          :phone="telephone"
          :comments="comments"
        />
        <CommentForm
          v-if="dossierState === 'En attente de lancement'"
          :comments="comments"
          @update-comments="updateComments"
        />
        <!-- <CommentFormReadOnly
          v-if="comments && comments.trim() !== '' && dossierState !== 'En attente de lancement' && dossierState !== 'En attente de complément d\'informations'"
          :comments="comments"
          :dossierState="dossierState"
        /> -->
        <Diagnostic 
        v-if="dossierState === 'En attente de lancement'"
          ref="diagnosticComponent" 
          :registeredComptes="registeredComptes" 
          :unregisteredComptes="unregisteredComptes" 
          :dossierState="dossierState || ''" 
          :defuntName="defuntName" 
          :fetchSiteMappings="fetchSiteMappings"
          :siteMappings="siteMappings"
          :siteMappingsReady="siteMappingsReady"
          :isReadOnly="dossierState === 'Prévalidation' || dossierState === 'Réalisation des démarches'"
          :demarches="demarches"
          :isLoadingDemarches="isLoadingDemarches"
          :accounts="accounts"
          @lancer-demarches="lancerDemarches"
        />
        <div v-if="isLoadingDemarches" class="skeleton-container">
          <SkeletonLoader />
        </div>
        <div v-else>
          <SuiviDemarches 
          v-if="(dossierState === 'Réalisation des démarches' || dossierState === 'Stoppé' || dossierState === 'Prévalidation' || dossierState === 'Terminé') && demarches.length > 0"
          :demarches="demarches" 
          :dossierState="dossierState"
          :scanEnCours="scanEnCours"
          @stopper-demarches="stopperDemarches"
        />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormulaireInfo from './components/FormulaireInfo.vue';
import ComplementInfo from './components/ComplementInfo.vue';
import InfoForm from './components/InfoForm.vue';
import InfoFormReadOnly from './components/InfoFormReadOnly.vue'; // Import du nouveau composant
import Diagnostic from './components/Diagnostic.vue';
import SuiviDemarches from './components/SuiviDemarches.vue';
import CommentForm from './components/CommentForm.vue';
// import CommentFormReadOnly from './components/CommentFormReadOnly.vue'; // Import du nouveau composant
import { logErrorToDiscord } from './logService';
import defaultLogo from '@/assets/defaultIcon.png';
import SkeletonLoader from './components/SkeletonLoader.vue';

export default {
  name: 'App',
  components: {
    FormulaireInfo,
    ComplementInfo,
    InfoForm,
    InfoFormReadOnly, // Déclaration du nouveau composant
    Diagnostic,
    SuiviDemarches,
    CommentForm,
    SkeletonLoader
  },
  data() {
    return {
      contactName: '',
      registeredComptes: [],
      unregisteredComptes: [],
      dossierState: null,
      dossierTitre: '',
      prenomDefunt: '',
      email: '',
      telephone: '',
      comments: '',
      errorMessage: '',
      scanEnCours: false,
      defuntName: '',
      siteMappings: [],
      siteMappingsReady: false,
      emailPouvoir: '',
      tallyLink: '',
      etatFormulaire: '',
      demarches: [],
      isLoading: false,
      isLoadingDemarches: false,
      marque: '',
      conseiller: '',
      commentsRd: '',
      uploadedFiles: {},
      documentsManquants: '',
      isLoadingDocuments: false,
      isSendingMail: false,
      mailSent: false,
      accounts: [],
      id_funecap: '',
      isLoadingDossier: false
    };
  },
  computed: {
    idDossier() {
      return this.$route.query.id || '';
    },
    isCommentsEmpty() {
      return this.comments.trim() === '';
    }
  },
  watch: {
    idDossier(newId) {
      if (newId) {
        this.fetchDossierState(newId);
      } else {
        this.errorMessage = 'Erreur: Aucun ID de dossier fourni.';
      }
    }
  },
  methods: {
    removeFile(type) {
      const newFiles = { ...this.uploadedFiles };
      delete newFiles[type];
      this.uploadedFiles = newFiles;
    },
    handleFileUpload(type, event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadedFiles = { ...this.uploadedFiles, [type]: file };
      }
    },
    isPdf(file) {
      return file.type === 'application/pdf';
    },
    getObjectURL(file) {
      return URL.createObjectURL(file);
    },
    async fetchSiteMappings() {
      let allRecords = [];
      let offset = '';

      const pause = (duration) => new Promise(resolve => setTimeout(resolve, duration));

      try {
        do {
          const response = await fetch(`https://api.airtable.com/v0/appBlVsssU6PaLkuN/tbluDVSbiPKovy9CA?offset=${offset}`, {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer patMlMaIReBYnCaRI.5e610df6fb9e10bd54270417562883a99c37266a39cb43875d09231d69202ab0'
            }
          });

          if (response.status === 429) {
            console.warn('Rate limit exceeded, waiting before retrying...');
            await pause(1000);
            continue;
          }

          const data = await response.json();
          allRecords = [...allRecords, ...data.records];
          offset = data.offset ? data.offset : '';

          await pause(100);

        } while (offset);

        this.siteMappings = allRecords.map(record => ({
          name: record.fields.logo_url || record.fields.nom,
          id: record.id,
          categories: record.fields.catégorie || []
        }));
        this.siteMappingsReady = true;
        console.log("App.vue : this.siteMappings ", this.siteMappings);
      } catch (error) {
        console.error('Error fetching site mappings:', error);
        this.siteMappings = [];
        this.siteMappingsReady = true;
      }
    },
    updateComptes(comptes) {
      this.registeredComptes = comptes.registeredComptes;
      this.unregisteredComptes = comptes.unregisteredComptes;
    },
    clearComptes() {
      this.registeredComptes = [];
      this.unregisteredComptes = [];
    },
    updateEmail(email) {
      this.email = email;
    },
    updatePhone(phone) {
      this.telephone = phone;
    },
    updateComments(comments) {
      this.comments = comments;
    },
    setScanEnCours(status) {
      this.scanEnCours = status;
    },
    async fetchDossierState(id) {
      if (!id) {
        this.errorMessage = 'Erreur: Aucun ID de dossier fourni.';
        return;
      }
      this.isLoadingDossier = true;
      try {
        const response = await fetch(`https://api.airtable.com/v0/appBlVsssU6PaLkuN/tblk6g1L7ccQadJtO/${id}`, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer patMlMaIReBYnCaRI.5e610df6fb9e10bd54270417562883a99c37266a39cb43875d09231d69202ab0'
          }
        });
        // test
        const data = await response.json();
        console.log("Fetched data:", data);
        if (data.fields) {
          this.dossierState = data.fields.état || 'Inconnu';
          this.dossierTitre = data.fields["proche - défunt"] || 'Titre inconnu';
          this.contactName = this.dossierTitre;
          this.defuntName = `${data.fields["prénom_défunt"][0]} ${data.fields["nom_défunt"][0]}`;
          this.prenomDefunt = data.fields["prénom_défunt"][0] || '';
          this.nomDefunt = data.fields["nom_défunt"][0] || '';
          this.prenomProche = data.fields["prénom_proche"][0] || '';
          this.email = data.fields.emails_defunt || '';
          this.telephone = data.fields.telephones_defunt || '';
          this.comments = data.fields.commentaires || '';
          this.commentsRd = data.fields.commentaire_rd || '';
          this.emailPouvoir = data.fields.email_proche?.[0] || '';
          this.tallyLink = data.fields.tally || '';
          this.etatFormulaire = data.fields.état_formulaire || '';
          this.documentsManquants = data.fields.documents_manquants || '';
          this.marque = data.fields.marque_blanche || '';
          this.conseiller = data.fields.conseiller || '';
          this.id_funecap = data.fields.id_fune || '';

          if (this.dossierState == "Réalisation des démarches" || this.dossierState == 'Stoppé' || this.dossierState === 'Prévalidation' || this.dossierState === 'Terminé') {
            this.fetchDemarches(id);
          }
        } else {
          this.errorMessage = 'Erreur: Les champs de données sont indéfinis.';
          console.error('Data fields are undefined:', data);
        }
        console.log("Dossier état:", this.dossierState);
      } catch (error) {
        this.errorMessage = 'Erreur lors de la récupération de l\'état du dossier.';
        console.error('Error fetching dossier state:', error);
      } finally {
        this.isLoadingDossier = false;
      }
    },
    async fetchDemarches(id) {
      this.isLoadingDemarches = true;
      try {
        const response = await fetch(`https://api.reposdigital.fr/intern/get-demarches/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        console.log("Fetched démarches:", data);
        this.demarches = data.demarches || [];
      } catch (error) {
        console.error('Error fetching démarches:', error);
      } finally {
        this.isLoadingDemarches = false;
      }
    },
    async lancerDemarches() {
      this.isLoading = true;
      try {
        
        await this.$refs.diagnosticComponent.lancerDemarches();
        const response = await fetch(`https://api.airtable.com/v0/appBlVsssU6PaLkuN/tblk6g1L7ccQadJtO/${this.idDossier}`, {
          method: 'PATCH',
          headers: {
            'Authorization': 'Bearer patMlMaIReBYnCaRI.5e610df6fb9e10bd54270417562883a99c37266a39cb43875d09231d69202ab0',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            fields: {
              emails_defunt: this.email,
              telephones_defunt: this.telephone,
              commentaires: this.comments,
              état: "Prévalidation"
            }
          })
        });
        const data = await response.json();
        console.log('Dossier updated:', data);
        this.fetchDossierState(this.idDossier);
        await this.fetchDemarches(this.idDossier);

        // PATCH FUNECAP
        await fetch('https://funecap.reposdigital.fr/patch-funecap', {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id_dossier_funecap: this.id_funecap,
            fun_suivi_repos_digital: 4,
            fun_lien_repos_digital: "https://interfacefunecap.reposdigital.fr/?id=" + this.idDossier
          })
        });

      } catch (error) {
        console.error('Error updating dossier:', error);
      } finally {
        this.isLoading = false;
        console.log("PATCH effectué");

        // Attendre 20 secondes avant de faire la requête
        setTimeout(async () => {
          try {
            const response = await fetch(`https://api.airtable.com/v0/appBlVsssU6PaLkuN/tblk6g1L7ccQadJtO/${this.idDossier}`, {
              method: 'GET',
              headers: {
                'Authorization': 'Bearer patMlMaIReBYnCaRI.5e610df6fb9e10bd54270417562883a99c37266a39cb43875d09231d69202ab0'
              }
            });
            const data = await response.json();
            if (data.fields) {
              this.etatFormulaire = data.fields.état_formulaire || '';
            } else {
              console.error('Data fields are undefined:', data);
            }
          } catch (error) {
            console.error('Error fetching dossier state:', error);
          }
        }, 20000); // 20 secondes
      }
    },
    async renvoyerMail() {
      this.isSendingMail = true;
      this.mailSent = false;
      try {
        const response = await fetch('https://kilianwdt.app.n8n.cloud/webhook/92ca3b5a-a630-4db7-ae64-9e4db6644796', {
          method: 'POST',
          body: JSON.stringify({
            marque: this.marque,
            conseiller: this.conseiller,
            destinataire: [this.emailPouvoir],
            prenom_proche: [this.prenomProche],
            prenom_defunt: [this.prenomDefunt],
            nom_defunt: [this.nomDefunt],
            formulaire: this.tallyLink,
            id_dos: this.idDossier
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        console.log(data);
        this.mailSent = true;
      } catch (error) {
        console.error('Erreur lors de l\'envoi du mail:', error);
      } finally {
        this.isSendingMail = false;
      }
    },
    async stopperDemarches() {
      fetch(`https://api.airtable.com/v0/appBlVsssU6PaLkuN/tblk6g1L7ccQadJtO/${this.idDossier}`, {
        method: 'PATCH',
        headers: {
          'Authorization': 'Bearer patMlMaIReBYnCaRI.5e610df6fb9e10bd54270417562883a99c37266a39cb43875d09231d69202ab0',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          fields: {
            état: 'Stoppé'
          }
        })
      })
      .then(response => response.json())
      .then(data => {
        console.log('Dossier mis à jour:', data);
        this.fetchDossierState(this.idDossier);
      })
      .catch(error => {
        console.error('Erreur lors de la mise à jour de l\'état du dossier:', error);
      });

      // PATCH FUNECAP
      await fetch('https://funecap.reposdigital.fr/patch-funecap', {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id_dossier_funecap: this.id_funecap,
            fun_suivi_repos_digital: 8,
            fun_lien_repos_digital: "https://interfacefunecap.reposdigital.fr/?id=" + this.idDossier
          })
        });
    },
    isDocumentManquant(document) {
      return this.documentsManquants.includes(document);
    },
    async uploadFileToTempStorage(file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('expires', '1d');

      const response = await fetch('https://file.io', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Erreur lors du téléchargement du fichier.');
      }

      const data = await response.json();
      return data.link;
    },
    async modifierDocuments() {
      const fields = {};
      this.isLoadingDocuments = true;

      async function sendDiscordMessage(message) {
        const webhookUrl = "https://discord.com/api/webhooks/1247186929137487933/Ak9vs_R6RUK2ANjqE3ID-Xr8tAE6N6ptl0tkjqqu4YUbavMJekxjutJ5SQQcP24Vnigk";
        try {
          await fetch(webhookUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              content: message,
            }),
          });
        } catch (error) {
          console.log("erreur sendDiscordMessage : " + error);
        }
      }

      try {
        if (this.uploadedFiles.acteDeDeces) {
          const url = await this.uploadFileToTempStorage(this.uploadedFiles.acteDeDeces);
          fields.acte_décès = [{ url }];
        }
        if (this.uploadedFiles.carteIdentitePouvoir) {
          const url = await this.uploadFileToTempStorage(this.uploadedFiles.carteIdentitePouvoir);
          fields.cni = [{ url }];
        }
        if (this.uploadedFiles.mandat) {
          const url = await this.uploadFileToTempStorage(this.uploadedFiles.mandat);
          fields.mandat = [{ url }];
        }
        if (this.uploadedFiles.preuveLienParente) {
          const url = await this.uploadFileToTempStorage(this.uploadedFiles.preuveLienParente);
          fields.livret_famille = [{ url }];
        }
        if (this.uploadedFiles.acteNotarie) {
          const url = await this.uploadFileToTempStorage(this.uploadedFiles.acteNotarie);
          fields.acte_notarié = [{ url }];
        }

        console.log(fields);

        const response = await fetch(`https://api.airtable.com/v0/appBlVsssU6PaLkuN/tblk6g1L7ccQadJtO/${this.idDossier}`, {
          method: 'PATCH',
          headers: {
            'Authorization': 'Bearer patMlMaIReBYnCaRI.5e610df6fb9e10bd54270417562883a99c37266a39cb43875d09231d69202ab0',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ fields })
        });

        if (!response.ok) {
          throw new Error('Erreur lors de la mise à jour des documents.');
        }

        const data = await response.json();
        console.log('Documents mis à jour:', data);

        const updateResponse = await fetch(`https://api.airtable.com/v0/appBlVsssU6PaLkuN/tblk6g1L7ccQadJtO/${this.idDossier}`, {
          method: 'PATCH',
          headers: {
            'Authorization': 'Bearer patMlMaIReBYnCaRI.5e610df6fb9e10bd54270417562883a99c37266a39cb43875d09231d69202ab0',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            fields: {
              état: "Réalisation des démarches",
              commentaire_rd: "",
              documents_manquants: []
            }
          })
        });

        if (!updateResponse.ok) {
          throw new Error('Erreur lors de la mise à jour de l\'état du dossier.');
        }

        console.log('État du dossier mis à jour:', await updateResponse.json());

        let messageContent = "";

        await fetch('https://funecap.reposdigital.fr/patch-funecap', {
          method: 'PATCH',
          body: JSON.stringify({
            id_dossier_funecap: this.id_funecap,
            fun_suivi_repos_digital: 4,
            fun_lien_repos_digital: "https://interfacefunecap.reposdigital.fr/?id=" + this.idDossier
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }).then(async (response) => {
          console.log(response);
          messageContent = `De nouveaux documents ont été saisis par un conseiller SERENICARE.`;
          await sendDiscordMessage(messageContent);
        }).catch((error) => console.error('Error:', error));

        this.fetchDossierState(this.idDossier);
      } catch (error) {
        console.error('Error updating documents:', error);
      } finally {
        this.isLoadingDocuments = false;
      }
    },
    async fetchAccounts() {
      let offset = null;
      const allAccounts = [];

      do {
        const response = await fetch(`https://api.airtable.com/v0/appBlVsssU6PaLkuN/tbluDVSbiPKovy9CA?offset=${offset || ''}`, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer patMlMaIReBYnCaRI.5e610df6fb9e10bd54270417562883a99c37266a39cb43875d09231d69202ab0'
          }
        });

        if (!response.ok) {
          console.error('Error fetching accounts:', response.statusText);
          logErrorToDiscord('Error fetching accounts:', response.statusText);
          return;
        }

        const data = await response.json();
        allAccounts.push(...data.records.map(record => {
          const name = record.fields.nom ? record.fields.nom.toLowerCase() : 'default';
          return {
            id: record.id,
            name: record.fields.nom || 'Compte inconnu',
            logo: `https://logos-comptes.s3.eu-west-3.amazonaws.com/${name}.png`
          };
        }));

        offset = data.offset;
      } while (offset);

      this.accounts = allAccounts;
      this.setFallbackLogos();
    },
    setFallbackLogos() {
      const defaultLogoUrl = defaultLogo;
      this.accounts.forEach(account => {
        const img = new Image();
        img.src = account.logo;
        img.onerror = () => {
          account.logo = `https://logo.clearbit.com/${account.name.toLowerCase().replace(/\s+/g, '')}.com`;
          const imgFallback = new Image();
          imgFallback.src = account.logo;
          imgFallback.onerror = () => {
            account.logo = defaultLogoUrl;
          };
        };
      });
    }
  },
  async mounted() {
    await this.fetchSiteMappings();
    await this.fetchAccounts();
    const checkIdInterval = setInterval(() => {
      if (this.idDossier) {
        clearInterval(checkIdInterval);
      }
    }, 100);
  }
}
</script>

<style>
body {
  font-family: 'Outfit', sans-serif !important; 
  margin: 0;
  padding: 20px;
  background-color: #f3f4f7;
}

.error {
  color: red;
  font-weight: bold;
}

html, body, #app {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  box-sizing: border-box;
  height: 100vh;
  overflow-y: auto;
}

.error {
  color: red;
  font-weight: bold;
  text-align: center;
}

/* Ajout des styles pour centrer le skeleton loader */
.skeleton-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
